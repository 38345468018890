import classnames from "classnames";
import React, { Component } from "react";
import AddToCalendar from "react-add-to-calendar";
import DataTable from "react-data-table-component";
import { Search } from "react-feather";
import ReactPlayer from "react-player";
import firebase from 'firebase/compat/app';
import moment from "moment";
import Sticky from "react-stickynode";

import "../../../node_modules/react-add-to-calendar/dist/react-add-to-calendar.min.css";
import _ from "lodash";
// import test from './test.mp4';
// import Draggable from 'react-draggable'; // The default
// import "../../../assets/scss/components/customizer.scss"
import { connect } from "react-redux";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";
import { changeAgendaVideo } from "../../redux/actions/customizer/index";
import Agendades from "./Agendades";
import { AiFillCaretRight } from "@react-icons/all-files/ai/AiFillCaretRight";
// import 'react-add-to-calendar/dist/react-add-to-calendar.css'
class AgendaDetail extends Component {
  state = {
    modal: false,
    isloading: true,
    videoloading: true,
    showagenda: false,
    currentvideo: null,
    modaltitle: "",
    active: "day1",
    // value: "",
    videoname: "",
    currentdata: [],
    filteredData: [],
    alltypes: [],
    alldates: [],
    selecteddata: [],
    value: "",
    paginationPageSize: 20,
    currenPageSize: "",
    getPageSize: "",
  };
  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.selectedagenda;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        return item.title.toLowerCase().includes(value.toLowerCase());
        // let startsWithCondition =
        //   item.title.toLowerCase().startsWith(value.toLowerCase())
        // let includesCondition =
        //   item.title.toLowerCase().includes(value.toLowerCase()) ||
        //   item.date.toLowerCase().includes(value.toLowerCase()) ||
        //   item.email.toLowerCase().includes(value.toLowerCase()) ||
        //   item.revenue.toLowerCase().includes(value.toLowerCase()) ||
        //   item.status.toLowerCase().includes(value.toLowerCase())

        // if (startsWithCondition) {
        //   return startsWithCondition
        // } else if (!startsWithCondition && includesCondition) {
        //   return includesCondition
        // } else return null
      });
      var filterdata = _.orderBy(filteredData, ["stime"], ["asc"]);
      this.setState({ filteredData: filterdata });
    }
  };

  componentDidMount() {
    // var data = this.props.location.state;
    var data = this.props.maindata;
    this.setState({
      selectedtype: data.selectedtype,
      alldates: data.alldates,
      selecteddate: data.selecteddate,
      selectedagenda: data.selectedagenda,
      filteredData: data.selectedagenda,
      isloading: false,
      allagenda: data.allagenda,
    });
  }

  render() {
    // const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    // var data = this.state.value.length ? this.state.filteredData : this.state.currentdata;
    if (this.state.isloading) {
      return <Spinner />;
    } else {
      // var agendakeys = Object.keys(agendadata);
      // console.log("In showagenda");
      // console.log(currentdata);
      return (
        <div className="">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#9E3039",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            {this.state.alldates.map((key, i) => {
              var kdata = key.split(" ");
              return (
                <div key={key}>
                  <div
                    style={{
                      // fontWeight: "bold",
                      width: 50,
                      marginLeft: 5,
                      marginRight: 5,
                      // height: 50,
                      // borderRadius: "50%",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      // backgroundColor: "#9E3039",
                    }}
                    className={classnames({
                      active: this.state.selecteddate === key,
                    })}
                  >
                    <div style={{ fontSize: 8 }}>{kdata[2]}</div>
                    {/* <div style={{ fontSize: 12 }}>{kdata[1]}</div> */}
                    {/* <div style={{ fontSize: 8 }}>{kdata[3]}</div> */}
                  </div>
                </div>
              );
            })}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: 2,
              paddingBottom: 2,
              // backgroundColor: "#F0F0F2",
            }}
          >
            {this.state.alldates.map((key, i) => {
              var kdata = key.split(" ");
              return (
                <div key={key}>
                  <div
                    style={{
                      // fontWeight: "bold",
                      width: 50,
                      height: 50,
                      marginLeft: 5,
                      marginRight: 5,
                      borderRadius: "50%",
                      color:
                        this.state.selecteddate === key ? "#fff" : "#9E3039",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      backgroundColor:
                        this.state.selecteddate === key ? "#9E3039" : "#fff",
                    }}
                    onClick={() => {
                      var filterdata = _.orderBy(
                        this.state.allagenda[this.state.selectedtype][key],
                        ["stime"],
                        ["asc"]
                      );
                      this.setState({
                        selecteddate: key,
                        selectedagenda:
                          this.state.allagenda[this.state.selectedtype][key],
                        filteredData: filterdata,
                      });
                      // this.toggletab(key, agendadata, active);
                    }}
                    className={classnames({
                      active: this.state.selecteddate === key,
                    })}
                  >
                    <div style={{ fontSize: 8 }}>{kdata[0]}</div>
                    <div style={{ fontSize: 12 }}>{kdata[1]}</div>
                    <div style={{ fontSize: 8 }}>{kdata[3]}</div>
                  </div>
                </div>
              );
            })}
          </div>

          <Sticky  bottomBoundary="#content">
            <div
              style={{
                backgroundColor: "#FCFCFC",
                padding: 3,
                color: "#9E3039",
                fontSize: 17,
              }}
            >
              {this.state.selecteddate}
            </div>
          </Sticky>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <TabContent
              id="content"
              key="content"
              style={{ marginTop: 10, width: "100%", height: "90%" }}
              activeTab={this.state.selecteddate}
            >
              <TabPane
                key={this.state.selecteddate}
                tabId={this.state.selecteddate}
                style={{ height: "100%", width: "100%" }}
              >
                {/* <div className="d-flex flex-wrap justify-content-between">
                  <div
                    className="position-relative has-icon-left mb-1"
                    style={{ width: "100%" }}
                  >
                    <Input
                      value={this.state.value}
                      onChange={(e) => this.handleFilter(e)}
                    />
                    <div className="form-control-position">
                      <Search size="15" />
                    </div>
                  </div>
                </div> */}
                {this.state.filteredData.map((row) => {
                  return (
                    <>
                      <Agendades
                        redirecttopage={this.props.redirecttopage}
                        row={row}
                      />
                      {/* <hr style={{ width: "90%" }} /> */}
                    </>
                  );
                })}
              </TabPane>
            </TabContent>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    eventdata: state.auth.login.eventdata,
    // customizerstate: state.customizer.customizer.customizerState,
    // pollcustomizer: state.customizer.customizer.pollcustomizer
  };
};
export default connect(mapStateToProps, {
  changeAgendaVideo,
})(AgendaDetail);
