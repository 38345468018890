import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  // {
  //   id: "welcome",
  //   title: "Welcome Note",
  //   modaltype: "video",
  //   modalsrc: "https://www.youtube.com/watch?v=zzmQAA4e0f4",
  //   type: "modal",
  //   icon: <Icon.Calendar size={16} />,
  //   permissions: ["admin", "editor"]
  // },
  {
    id: "home",
    title: "Lobby",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/",
  },
  {
    id: "exhibitors",
    title: "Exhibitors",
    type: "dropdown",
    icon: <Icon.Circle size={10} />,
    children: [
      {
        id: "page1",
        title: "Page 1",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/exhibitors/page1",
        parentOf: [
          "/exhibitordata/id0",
        ]
      },
      {
        id: "page2",
        title: "Page 2",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/exhibitors/page2"
      }
    ]
  },
  {
    id: "agenda",
    title: "Agenda",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/agenda"
  },
  // {
  //   id: "main",
  //   title: "Feed",
  //   type: "item",
  //   icon: <Icon.Home size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/feed",
  // },
  // {
  //   id: "attendes",
  //   title: "Attendees",
  //   type: "item",
  //   icon: <Icon.Circle size={10} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/data-list/thumb-view"
  // },

  {
    id: "helpdesk",
    title: "Help Desk",
    type: "item",
    icon: <Icon.File size={20} />,
    navLink: "/helpdesk",
  },

  {
    id: "resource",
    title: "Resource Center",
    type: "item",
    icon: <Icon.File size={20} />,
    navLink: "/resource",
  },

  {
    id: "lounge",
    title: "Lounge",
    type: "item",
    icon: <Icon.File size={20} />,
    navLink: "/lounge",
  },

  {
    id: "Auditorium",
    title: "Auditorium",
    type: "item",
    icon: <Icon.File size={20} />,
    navLink: "/auditorium",
  },

  // {
  //   id: "leaderboard",
  //   title: "Leaderboard",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   navLink: "/leaderboard",
  // },


  // {
  //   id: "faq",
  //   title: "FAQ",
  //   type: "item",
  //   icon: <Icon.HelpCircle size={16} />,
  //   navLink: "/pages/faq",
  //   permissions: ["admin", "editor"]
  // },

  // {
  //   id: "slider",
  //   title: "Slider",
  //   type: "item",
  //   icon: <Icon.Home size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/slider",
  // }
  // ,
  // {
  //   id: "page2",
  //   title: "Page 2",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/page2",
  // }

]

export default horizontalMenuConfig
