import React from "react";
import { history } from "../../history";

import { AiFillSchedule } from "@react-icons/all-files/ai/AiFillSchedule";
import { MdSchedule } from "@react-icons/all-files/md/MdSchedule";
// import { IoLocation } from "@react-icons/all-files/io";
import { AiOutlineSchedule } from "@react-icons/all-files/ai/AiOutlineSchedule";
import { MdLocationPin } from "@react-icons/all-files/md/MdLocationPin";
// import { CgFeed } from "@react-icons/all-files/cg/CgFeed";
import { BsBagDash } from "@react-icons/all-files/bs/BsBagDash";

import { GoHome } from "@react-icons/all-files/go/GoHome";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";

import { MdQrCodeScanner } from "@react-icons/all-files/md/MdQrCodeScanner";
import "../../assets/scss/plugins/extensions/swiper.scss";

const params = {
  lazy: true,
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  // autoplay: {
  //   delay: 2500,
  //   disableOnInteraction: false,
  // },
};
class SmartHome extends React.Component {
  state = {
    currenturl: "/",
  };

  componentDidMount() {
    console.log("In custom naviation");
    console.log(this.props);
    history.listen((location, action) => {
      console.log(`The current URL is ${location.pathname}`);
      this.setState({
        currenturl: location.pathname,
      });
    });
  }

  render() {
    var currenturl = this.state.currenturl;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
          borderRadius: 40,
        }}
      >
        {/* {this.props.adshide?null:
        <div
          style={{
            height: 100,
            paddingLeft: 10,
            paddingRight: 10,
          
          }}
        >
          <Swiper {...params}>
            <div
              onClick={() => {
                window.open("https://xcape.in/");
              }}
              style={{display:"flex",justifyContent:"center"}}
            >
              <img
                height={100}
                className="swiper-lazy sliderclass"
                src={"/app_images/bottomimage/image1.jpeg"}
                alt="swiper 3"
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-black" />
            </div>
            <div
              onClick={() => {
                window.open("https://xcape.in/");
              }}
              style={{display:"flex",justifyContent:"center"}}
            >
              <img
                height={100}
                className="swiper-lazy sliderclass"
                src={"/app_images/bottomimage/image2.jpeg"}
                alt="swiper 3"
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-black" />
            </div>
          </Swiper>
        </div>} */}
        <div
          style={{
            backgroundColor: "#fff",
            marginTop: 5,
            width: "100%",
            height: 60,
            display: "flex",
            // borderRadius: 40,
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 1,
              textAlign: "center",
              // borderRight: "2px solid #9E3039",
              // backgroundColor: currenturl === "/" ? "#9E3039" : "#fff",
              padding: 5,
              color: currenturl === "/" ? "#9E3039" : "#000",
            }}
            onClick={() => {
              history.push("/");
            }}
          >
            <GoHome size={20} />
            <div style={{ paddingTop: 5, fontSize: "3vw" }}>Home</div>
          </div>
          <div
            style={{
              flex: 1,
              // borderRight: "2px solid #9E3039",
              textAlign: "center",
              // backgroundColor: currenturl === "/visaticket" ? "#9E3039" : "#fff",
              padding: 5,
              color: currenturl === "/faq" ? "#9E3039" : "#000",
            }}
            onClick={() => {
              history.push("/faq");
              // this.props.changesmartnavigationstate(1)
            }}
          >
            <AiFillSchedule size={20} />
            <div style={{ paddingTop: 5, fontSize: "3vw" }}>FAQ</div>
          </div>
          <div
            style={{
              flex: 1,
              justifyContent:"center",
              // borderRight: "2px solid #9E3039",
              textAlign: "center",
              display: "flex",
              padding: 5,
              zIndex: 1,
              color: "#fff",
            }}
            onClick={() => {
              history.push("/qrtabs");
              // this.props.changesmartnavigationstate(1)
            }}
          >
            <div
              style={{
                backgroundColor: "#9E3039",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: "50%",
                height: 60,
                // padding: 10,
                marginTop: -15,
                width: 60,
              }}
            >
              <MdQrCodeScanner size={35} />
            </div>
          </div>
          <div
            onClick={() => {
              history.push("/feed");
              // this.props.changesmartnavigationstate(2)
              // this.setState({
              //   selected:2
              // })
            }}
            style={{
              flex: 1,

              textAlign: "center",
              // borderRight: "2px solid #9E3039",
              // backgroundColor: currenturl === "/feed" ? "#9E3039" : "#fff",
              padding: 5,
              color: currenturl === "/feed" ? "#9E3039" : "#000",
            }}
          >
            <BsBagDash size={20} />
            <div style={{ paddingTop: 5, fontSize: "3vw" }}>Feed</div>
          </div>{" "}
          <div
            onClick={() => {
              history.push("/agenda");
              // this.props.changesmartnavigationstate(3)
            }}
            style={{
              flex: 1,
              textAlign: "center",

              // backgroundColor: currenturl === "/agenda" ? "#9E3039" : "#fff",
              padding: 5,
              color: currenturl === "/agenda" ? "#9E3039" : "#000",
            }}
          >
            <AiOutlineSchedule size={20} />
            <div style={{ paddingTop: 5, fontSize: "3vw" }}>Agenda</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SmartHome;
