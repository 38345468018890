import React from "react";
import {
  Col, ListGroup,
  ListGroupItem, Row
} from "reactstrap";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";

class Videomodel extends React.Component {
  state = {
    currentlyselected: 0,
    currentdocument: "",
    spinner: true
  };
  componentDidMount() {
    console.log("In documentmodal");
    console.log(this.props.data);
    console.log(this.props.data[0].url);
    this.setState({
      currentdocument: this.props.data.length > 0 ? this.props.data[0].url : ""
    })
    this.props.onloadcall();
  }
  render() {
    return (
      <React.Fragment>
        <Row style={{ height: "100%" }}>
          <Col md="8" sm="8" >
            {this.state.spinner ?
              < Spinner /> : null}
            <iframe title="Video" onLoad={() => {
              this.setState({ spinner: false })
            }} src={this.state.currentdocument} height="100%" width="100%" />
          </Col>
          <Col md="4" sm="4" style={{ overflowY: "auto", height: "100%" }} >

            <ListGroup>
              {/* <h4 className="mb-1 text-white" >
                Heading 1
              </h4> */}
              {this.props.data.map((d, i) => {
                return (
                  <ListGroupItem active={i === this.state.currentlyselected ? true : false} key={i} onClick={() => {
                    console.log("Clicked");
                    this.setState({
                      currentlyselected: i,
                      currentdocument: d.url,
                      spinner: true
                    })
                  }}>
                    <div className="d-flex justify-content-between w-100">
                      <h5 className="mb-1">
                        {d.name}
                      </h5>
                      {/* <Icon.Download onClick={() => {
                        console.log("Downloading");
                      }} /> */}
                      {/* <small>Download</small> */}
                    </div>
                  </ListGroupItem>
                )
              })}

              {/* <ListGroupItem >
                <div className="d-flex justify-content-between w-100">
                  <h5 className="mb-1 text-white">
                    Document 2
              </h5>
                  <small>3 days ago</small>
                </div>
                <p className="mb-1">
                  Donec id elit non mi porta gravida at eget metus. Maecenas
                  sed diam eget risus varius blandit.
            </p>
                <small>Donec id elit non mi porta.</small>
              </ListGroupItem> */}

            </ListGroup>
          </Col>
        </Row>
      </React.Fragment >
    )
  }
}

export default Videomodel