import firebase from "firebase/compat/app";
import React from "react";
import ReactDOM from "react-dom";
import {
  Calendar,
  CreditCard,
  SkipBack,
  MessageSquare,
  Send,
  ArrowLeft,
} from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import img from "../../../assets/img/user.png";
import Spinner from "../../../components/@vuexy/spinner/Loading-spinner";
import {
  togglebcardmodal,
  togglemeetingmodal,
} from "../../../redux/actions/auth/loginActions";
// import Flatpickr from "react-flatpickr";
// import { sendMessage, togglePinned } from "../../../redux/actions/chat/index"
class ChatLog extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.activeUser !== state.activeUser ||
      props.messages !== state.messages
    ) {
      // console.log("In get drevied state")
      return {
        activeUser: props.activeUser,
        messages: props.messages,
      };
    }
    // Return null if the state hasn't changed
    return null;
  }

  state = {
    msg: "",
    activeUser: null,
    messages: null,
    // modal: false,
  };
  getmessage = () => {};

  handleSendMessage = (id, isPinned, text) => {
    if (text.length > 0) {
      this.props.sendMessage(id, isPinned, text);
      this.setState({
        msg: "",
      });
    }
  };
  componentDidMount() {
    this.scrollToBottom();
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }

  handleTime = (time_to, time_from) => {
    const date_time_to = new Date(time_to);
    const date_time_from = new Date(time_from);
    return (
      date_time_to.getFullYear() === date_time_from.getFullYear() &&
      date_time_to.getMonth() === date_time_from.getMonth() &&
      date_time_to.getDate() === date_time_from.getDate()
    );
  };
  handlemeeting = (e) => {
    e.preventDefault();
    console.log(this.state.mtitle);
    console.log(this.state.mdes);
    console.log(this.state.dateTimePicker);
  };
  scrollToBottom = () => {
    const chatContainer = ReactDOM.findDOMNode(this.chatArea);
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  render() {
    const { activeUser } = this.state;
    let activeUserUid = activeUser && activeUser.id ? activeUser.id : null;
    // console.log("teeee")
    // console.log(this.state.messages);

    let renderChats = this.state.messages
      ? this.state.messages.map((chat, i) => {
          console.log("In render chat");
          console.log(chat);
          let renderSentTime = () => {
            if (
              i > 0 &&
              !this.handleTime(chat.time, this.state.messages[i - 1].time)
            ) {
              // console.log(chat.time);
              // console.log(this.state.messages[i - 1])
              // console.log("Showing date");
              return (
                <div className="divider">
                  <div className="divider-text">
                    {new Date(chat.time).getDate() +
                      " " +
                      new Date(chat.time).toLocaleString("default", {
                        month: "short",
                      })}
                  </div>
                </div>
              );
            } else if (i === 0) {
              return (
                <div className="divider">
                  <div className="divider-text">
                    {new Date(chat.time).getDate() +
                      " " +
                      new Date(chat.time).toLocaleString("default", {
                        month: "short",
                      })}
                  </div>
                </div>
              );
            }
          };
          let renderAvatar = () => {
            // if (i > 0) {
            if (chat.uid === this.props.user.uid) {
              return (
                <div className="chat-avatar">
                  <div className="avatar">
                    <img
                      src={
                        this.props.publicdata.pic
                          ? this.props.publicdata.pic
                          : img
                      }
                      alt={chat.name}
                      height="38"
                      width="38"
                    />
                    <span
                      className={`${
                        true ? "avatar-status-online" : "avatar-status-offline"
                      }`}
                    />
                  </div>
                  {/* <div className="avatar m-0">
                    <img
                      src={this.props.publicdata.pic ? this.props.publicdata.pic : img}
                      alt="chat avatar"
                      height="40"
                      width="40"
                    />
                  </div> */}
                </div>
              );
            } else {
              return (
                <div className="chat-avatar">
                  {/* <div className="avatar m-0">
                    <img
                      src={activeUser.pic ? activeUser.pic : img}
                      alt="chat avatar"
                      height="40"
                      width="40"
                    />
                  </div> */}
                  <div className="avatar">
                    <img
                      src={activeUser.pic ? activeUser.pic : img}
                      alt="chat avatar"
                      height="40"
                      width="40"
                    />
                    <span
                      className={`${
                        this.props.activeuseronline
                          ? "avatar-status-online"
                          : "avatar-status-offline"
                      }`}
                    />
                  </div>
                </div>
              );
            }
            // } else {
            //   return (
            //     <div className="chat-avatar">
            //       <div className="avatar m-0">
            //         <img
            //           src={chat.isSent ? userImg : activeUser.pic}
            //           alt="chat avatar"
            //           height="40"
            //           width="40"
            //         />
            //       </div>
            //     </div>
            //   )
            // }
          };
          var cmessage = chat.mes;
          var ccolor = false;
          if (cmessage.startsWith("{")) {
            try {
              var finalmessage = JSON.parse(cmessage);
              if (finalmessage.msg) {
                cmessage = finalmessage.msg;
                ccolor = true;
              }
            } catch (error) {
              // console.log(error);
            }
          }
          console.log(cmessage);
          return (
            <React.Fragment key={i}>
              {renderSentTime()}
              <div
                className={`chat ${
                  chat.uid !== this.props.user.uid ? "chat-left" : "chat-right"
                }`}
              >
                {renderAvatar()}
                <div className="chat-body">
                  <div
                    className="chat-content"
                    style={{
                      background: ccolor
                        ? "linear-gradient(118deg, #FF0000, rgba(13, 79, 157, 0.7))"
                        : null,
                      color: ccolor ? "#fff" : null,
                    }}
                  >
                    {cmessage}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })
      : null;

    return (
      <div className="content-right">
        <div className="chat-app-window">
          <div
            className={`start-chat-area ${
              activeUser !== null ? "d-none" : "d-flex"
            }`}
          >
            <span className="mb-1 start-chat-icon">
              <MessageSquare style={{ color: "#000" }} size={50} />
            </span>
            <h4
              className="py-50 px-1 sidebar-toggle start-chat-text"
              style={{ color: "#000" }}
              onClick={() => {
                this.props.mainSidebar(true);

                
                // if (this.props.mql.matches === false) {
                //   this.props.mainSidebar(true)
                // } else {
                //   return null
                // }
              }}
            >
              Start Conversation
            </h4>
          </div>
          <div
            className={`active-chat ${
              activeUser === null ? "d-none" : "d-block"
            }`}
          >
            <div className="chat_navbar">
              <header className="chat_header d-flex justify-content-between align-items-center p-1">
                <div className="d-flex align-items-center">
                  <div
                    style={{ padding: 5 }}
                    className="sidebar-toggle d-block mr-1"
                    onClick={() => this.props.mainSidebar(true)}
                  >
                    <ArrowLeft size={24} />
                  </div>

                  <div
                    className="avatar user-profile-toggle m-0 m-0 mr-1"
                    // onClick={() => this.props.handleReceiverSidebar("open")}
                  >
                    <img
                      src={
                        activeUser !== null
                          ? activeUser.pic
                            ? activeUser.pic
                            : img
                          : img
                      }
                      alt={activeUser !== null ? activeUser.name : ""}
                      height="40"
                      width="40"
                    />
                    <span
                      className={`${
                        this.props.activeuseronline
                          ? "avatar-status-online"
                          : "avatar-status-offline"
                      }`}
                    />
                    {/* <span
                      className={`
                    ${
                        activeUser !== null &&
                          activeUser.status === "do not disturb"
                          ? "avatar-status-busy"
                          : activeUser !== null && activeUser.status === "away"
                            ? "avatar-status-away"
                            : activeUser !== null && activeUser.status === "offline"
                              ? "avatar-status-offline"
                              : "avatar-status-online"
                        }
                    `}
                    /> */}
                  </div>
                  <h6 className="mb-0">
                    {activeUser !== null ? activeUser.name : ""}
                  </h6>
                </div>
                <div>
                  {this.props.eventdata.settings.bcard ? (
                    <>
                      <CreditCard
                        id="bcardicon"
                        style={{
                          marginRight: 15,
                          cursor: "pointer",
                          color: "#800000",
                        }}
                        size={24}
                        onClick={() => {
                          console.log("Buisness card shared");
                          firebase
                            .database()
                            .ref("audi")
                            .update({
                              bcard: firebase.database.ServerValue.increment(1),
                            });
                          this.props.togglebcardmodal({
                            bcardmodal: true,
                            bcarddata: {
                              cardto: activeUserUid,
                              cardtoname: activeUser.name,
                            },
                          });
                        }}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target="bcardicon"
                      >
                        Exchange Business Card
                      </UncontrolledTooltip>
                    </>
                  ) : null}
                  {/* {this.props.constat[activeUserUid] === "busy" ?

                    <VideoOff size={24} /> : */}
                  {/* {this.props.constat[activeUserUid] ? */}
                  {this.props.eventdata.settings.meetings ? (
                    <>
                      <Calendar
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("Start Video");
                          this.props.togglemeetingmodal({
                            mcardmodal: true,
                            mcarddata: {
                              cardto: activeUserUid,
                              cardtoname: activeUser.name,
                              username: this.props.publicdata.name,
                            },
                          });
                          // firebase.database().ref("audi").update({
                          //   vcall: firebase.database.ServerValue.increment(1)
                          // });
                          // var combineid = ""
                          // var id = activeUserUid;
                          // var cid = this.props.user.uid;
                          // if (id > cid) {
                          //   combineid = cid + "_" + id;
                          // } else {
                          //   combineid = id + "_" + cid;
                          // }
                          // var message = firebase.database().ref("chats/" + combineid);

                          // var messagekey = message.push().key;

                          // var lastmessage = {
                          //   mes: JSON.stringify({ "t": 1, "msg": "Video call request by: " + this.props.publicdata.name, "params": new Date().getTime() }),
                          //   time: firebase.database.ServerValue.TIMESTAMP,
                          //   by: cid,
                          //   to: id
                          // }
                          // var update = {};
                          // update["chats/" + combineid + "/" + messagekey] = {
                          //   mes: '{"t": 1,"msg": "Video call request"}',
                          //   time: firebase.database.ServerValue.TIMESTAMP,
                          //   uid: cid
                          // };
                          // update["chatd/" + id + "/" + cid] = {
                          //   lm: lastmessage,
                          //   us: firebase.database.ServerValue.increment(1)
                          // }
                          // update["chatd/" + cid + "/" + id] = {
                          //   lm: lastmessage,
                          //   us: 0
                          // }
                          // firebase.database().ref().update(update);
                        }}
                        size={24}
                      />
                    </>
                  ) : null}
                </div>
                {/* <span
                  className="favorite"
                  onClick={() => {
                    if (activeChat) {
                      this.props.togglePinned(
                        activeUser.uid,
                        !activeChat.isPinned
                      )
                    }
                  }}>
                  <Star
                    size={22}
                    stroke={
                      activeChat && activeChat.isPinned === true
                        ? "#FF9F43"
                        : "#626262"
                    }
                  />
                </span> */}
              </header>
            </div>
            <div
            style={{overflow:"scroll"}}
              // onClick={()=>{
              //   console.log("clicking bar")
              // }}
              className="user-chats"
              options={{
                wheelPropagation: false,
              }}
              ref={(el) => {
                this.chatArea = el;
              }}
            >
              <div className="chats">
                {this.state.messages !== null ? (
                  <div>{renderChats}</div>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
            <div className="chat-app-form">
              <form
                className="chat-app-input d-flex align-items-center"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.state.msg.length > 0) {
                    // this.props.sendMessage(id, isPinned, text)

                    var combineid = "";
                    var id = activeUserUid;
                    var cid = this.props.user.uid;
                    if (id > cid) {
                      combineid = cid + "_" + id;
                    } else {
                      combineid = id + "_" + cid;
                    }
                    var message = firebase.database().ref("chats/" + combineid);

                    var messagekey = message.push().key;

                    var lastmessage = {
                      mes: this.state.msg,
                      time: firebase.database.ServerValue.TIMESTAMP,
                    };
                    var update = {};
                  if(!this.props.activeuseronline){
                    console.log("Sending notification");
                    var notificationmessage = firebase.database().ref("chatnotification");

                    var notificationmessagekey = notificationmessage.push().key;
                    update["chatnotification/"+notificationmessagekey]={msg:this.props.publicdata.name+": "+this.state.msg,to:id,from:cid};
                  }      
                    update["chats/" + combineid + "/" + messagekey] = {
                      mes: this.state.msg,
                      time: firebase.database.ServerValue.TIMESTAMP,
                      uid: cid,
                    };
                    update["chatd/" + id + "/" + cid] = {
                      lm: lastmessage,
                      us: firebase.database.ServerValue.increment(1),
                    };
                    update["chatd/" + cid + "/" + id] = {
                      lm: lastmessage,
                      us: 0,
                    };
                    // console.log(update);
                    firebase.database().ref().update(update);
                    this.setState({
                      msg: "",
                    });
                  }
                  // this.handleSendMessage(
                  //   activeUser.uid,
                  //   false,
                  //   this.state.msg,
                  //   activeUser
                  // )
                }}
              >
                <Input
                  type="text"
                  className="message mr-1 ml-50"
                  placeholder="Type your message"
                  style={{ fontSize: 16 }}
                  value={this.state.msg}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      msg: e.target.value,
                    });
                  }}
                />
                <Button color="primary">
                  <Send className="d-lg-none" size={15} />
                  <span className="d-lg-block d-none">Send</span>
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    eventdata: state.auth.login.eventdata,

    chat: state.chatApp.chats,
    user: state.auth.login.values,
    publicdata: state.auth.login.data.publicdata,
    constat: state.auth.login.constat,
  };
};
export default connect(mapStateToProps, {
  togglebcardmodal,
  togglemeetingmodal,
})(ChatLog);
