// You can customize the theme with the help of this file

//Template config options
const themeConfig = {
  layout: "vertical", // options[String]: "vertical"(default), "horizontal"
  theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: true, // options[Boolean]: true, false(default)
  navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
  navbarType: "sticky", // options[String]: floating(default) / static / sticky / hidden
  footerType: "hidden", // options[String]: static(default) / sticky / hidden
  disableCustomizer: true, // options[Boolean]: true, false(default)
  hideScrollToTop: true, // options[Boolean]: true, false(default)
  menuTheme: "primary", // options[String]: primary / success / danger / info / warning / dark
  direction: "ltr", // options[String] : ltr / rtl
  customizerState: false,
  hometour: null,
  pollcustomizer: false,

  homevideo: false,
  agendavideo: null,
  notification: null,
  notimodal: false,
  notidata: null,
  bcardmodal: false,
  bcarddata: null,
  mcardmodal: false,
  mcarddata: null,
  smartnavigationstate:0,
  expodata:null
}

export default themeConfig
