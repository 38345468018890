import { createStore, applyMiddleware, compose } from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import rootReducer from "../reducers/rootReducer"

const middlewares = [thunk, createDebounce()]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const Tools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middlewares)
    : composeEnhancers(applyMiddleware(...middlewares));
const store = createStore(
  rootReducer,
  {},
  Tools
)

export { store }
