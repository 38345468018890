import React, { Component } from "react";
import AddToCalendar from "react-add-to-calendar";
// import test from './test.mp4';
// import Draggable from 'react-draggable'; // The default
// import "../../../assets/scss/components/customizer.scss"
import { connect } from "react-redux";
import { history } from "../../history";

import "../../../node_modules/react-add-to-calendar/dist/react-add-to-calendar.min.css";
import { changeAgendaVideo } from "../../redux/actions/customizer/index";
import { ArrowDownCircle ,ArrowUpCircle} from "react-feather";

// import 'react-add-to-calendar/dist/react-add-to-calendar.css'

class Agendades extends Component {
  state = {
    des: false,
    //  offset : 45000000
     offset : 0

  };

  render() {
    var row = this.props.row;
    var ctime = new Date().getTime()
    return (
      <div>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent:"center"
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              padding: 3,
              textAlign: "center",
              color: "#000",
              fontSize: 14,
            }}
          >
            {row.agtime.split("-")[0].split(" ")[4]}
           
          </div>
        </div> */}
      <div
        style={{
          display: "flex",
          border: "1px solid",
          minHeight: 100,
          margin:5
        }}
      >
        
        <div
          style={{
            padding: 5,
            width: "100%",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              color: row.titlecolor ? row.titlecolor : "#fff",
              marginTop: 0,
              fontSize: 16,
            }}
          >
            {row.title}
          </div>

          {/* <div dangerouslySetInnerHTML={{__html: block.text}} className="text"></div> */}
       
          <div
          dangerouslySetInnerHTML={{__html: row.redirect}} 
            style={{
              fontWeight: "bold",
              color: row.titlecolor ? row.titlecolor : "#fff",
              marginTop: 10,
              fontSize: 14,
            }}
          >
           
          </div>
          <br/>
          <div
            style={{
              fontWeight: "bold",
              color: row.titlecolor ? row.titlecolor : "#fff",
              marginTop: 0,
              fontSize: 15,
            }}
          >
            {row.title==="Onwards"?row.agtime.split("-")[0].split(" ")[4] :<>
            {row.agtime.split("-")[0].split(" ")[4]} - {row.agtime.split("-")[1].split(" ")[6]}</>}
          </div>
          
          {false&&row.des ? (
            <>
              {this.state.des ? (
                <>
                <p
                  style={{ marginTop: 5, marginBottom: 3 }}
                  dangerouslySetInnerHTML={{
                    __html: row.des,
                  }}
                ></p>
                 <div style={{ color: "#808080", marginTop: 15 }} onClick={()=>{
                  this.setState({
                    des:false
                  })
                }}>
                  Hide Description
                  <ArrowUpCircle style={{ color: "#808080", marginLeft: 5 }} />
                </div>
                </>
              ) : (
                <div style={{ color: "#808080", marginTop: 15 }} onClick={()=>{
                  this.setState({
                    des:true
                  })
                }}>
                  Read Description
                  <ArrowDownCircle style={{ color: "#808080", marginLeft: 5 }} />
                </div>
              )}
            </>
          ) : null}
          {/* <p style={{ marginTop: 3, marginBottom: 3 }}></p> */}
        </div>
        {/* <div
          className="data-list-action"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: 150,
            flexDirection: "column",
          }}
        >
          {row.atc ? (
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                // height: 100,
                width: 150,
                zIndex: 100,
              }}
            >
           
              <AddToCalendar
                buttonLabel="Add To Calendar"
                listItems={[
                  { google: "Google" },
                  { apple: "Apple" },
                  { outlook: "Outlook" },
                  { yahoo: "Yahoo" },
                ]}
                event={{
                  title: row.title,
                  description: "",
                  location: "https://"+window.location.hostname,
                  startTime: row.stime+this.state.offset,
                  endTime: row.etime+this.state.offset,
                }}
                // buttonTemplate={{
                //   "calendar-plus-o": "left",
                // }}
              />
            </div>
          ) : null}
          {(row.video && row.video.length > 0) ||
          (row.redirect && row.redirect.length > 0) ? (
            <div
              style={{
                backgroundColor: "#50C878",

                color: "#fff",
                cursor:"pointer",
                paddingTop: 10,
                paddingBottom:10,
                paddingLeft:20,
                paddingRight:20,
                borderRadius: "10%",

                display:"none"
              }}
              // className="round"
              onClick={() => {
                console.log("hh")
              
                if (row.video && row.video.length > 0) {
                  this.props.redirecttopage("/agendaaudi");
                  this.props.changeAgendaVideo(row.video);
               
                } else {
                  console.log(row);
                  if (row.newtab) {
                    window.open(row.redirect, "_blank");
                  } else {
                    console.log(row.redirect);
                    // history.push(row.redirect);
                    this.props.redirecttopage(row.redirect, row.video);
                  }
                }
              }}
            >
              {row.video && row.video.length > 0
                ? "Play"
                : this.props.agendadata
                ? "Visit"
                : "Join"}
            </div>
          ) : null}
        </div> */}
      </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    eventdata: state.auth.login.eventdata,
    // customizerstate: state.customizer.customizer.customizerState,
    // pollcustomizer: state.customizer.customizer.pollcustomizer
  };
};
export default connect(mapStateToProps, {
  changeAgendaVideo,
})(Agendades);
