import React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { changecurrenchatuser } from "../../redux/actions/chat/index";
import { customizerState } from '../../redux/actions/customizer';

class Contactmodal extends React.Component {
  state = {
    currentlyselected: 0,
    currentdocument: "",
    spinner: true
  };
  componentDidMount() {
    console.log("In documentmodal");
    console.log(this.props.data);
    // console.log(this.props.data[0].url);
    this.setState({
      currentdocument: this.props.data && this.props.data.length > 0 ? this.props.data[0].url : ""
    })
    this.props.onloadcall();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.data && this.props.data.length === 0 ?
          <h2 style={{ textAlign: "center" }}>No Contact Found</h2>
          :
          <Row style={{ height: "100%" }}>
            {this.props.data && this.props.data.length > 0 && this.props.data.map((d, i) => {
              return (
                <Col key={i} lg="12" md="12" sm="12" >
                  <Card >

                    <CardBody className="text-center">
                      <h4>{d.name}</h4>
                      {/* {d.email ? <p>{d.email}</p> : null} */}
                      {d.position ? <p>{d.position}</p> : null}
                      {d.company ? <p>{d.company}</p> : null}
                      {d.id ?
                        <div className="card-btns d-flex justify-content-center">

                          <Button.Ripple color="primary" className="round" onClick={() => {
                            // window.open("mailto:" + d.email)
                            console.log(d.id);
                            this.props.toggleModal();
                            this.props.customizerState(true);
                            this.props.changecurrenchatuser({
                              id: d.id,
                              name: d.name
                            });
                          }} >
                            Chat
                          </Button.Ripple>

                        </div> : null}

                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>}
      </React.Fragment >
    )
  }
}



export default connect(null, {
  customizerState,

  changecurrenchatuser,

}, null, { forwardRef: true })(Contactmodal);