import firebase from 'firebase/compat/app';
import _ from "lodash";
import React from "react";
import { MessageSquare, PlusCircle, Search, XCircle } from "react-feather";
import { connect } from "react-redux";
import { changecurrenchatuser } from  "../../../redux/actions/chat/index";

// import InfiniteScroll from 'react-infinite-scroll-component';
// import { FixedSizeList as List } from "react-window";
import { AutoSizer, List } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { Badge, Card, FormGroup, Input } from "reactstrap";
import img from "../../../assets/img/user.png";
import Spinner from "../../../components/@vuexy/spinner/Loading-spinner";
import {
  getChats,
} from "../../../redux/actions/chat/index";

// import {

//   tooglevideocallmodal
// } from "../../../redux/actions/customizer/index";

class ChatSidebar extends React.Component {
  state = {
    chatsContacts: [],
    contacts: [],
    // searchchatcontacts: [],
    messages: [],
    status: null,
    value: "",
    showcontacts: false,
    showloading: false,
    contactlist: [],
    algolia: false,
    // searchcontactlist: []
  };

  async componentDidMount() {
    console.log("Loading chatssss");
    // this.props.getChats().then(() => {
    //   this.setState({
    //     showloading: false,
    //   });
    // });
  }

  handleOnChange = (e) => {
    this.setState({ value: e.target.value });
  };
  getcontactlist = () => {
    console.log("Getting contact list");
    firebase
      .database()
      .ref("pub_user")
      .once("value", (snap) => {
        var mdata = snap.val();
      
        delete mdata[firebase.auth().currentUser.uid];
        var contactlist = _(mdata)
          .map(function (v, k) {
            // insert the key into the object
          
            return _.merge({}, v, { id: k });
          })
          .sortBy("name") // sort by name
          .value();
        console.log(contactlist);
        // contactlist.slice(0, 10);
        // var a = [];
        // a.push(contactlist);
        // a.push(contactlist);
        // a = a.concat(contactlist);
        // a = a.concat(contactlist);
        // a = a.concat(contactlist);
        // console.log(a);
        this.setState({
          showloading: false,
          contactlist: contactlist,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getfiltercontacts(array) {
    return array.filter((contact) => {
      if (contact.name) {
        return contact.name
          .toLowerCase()
          .includes(this.state.value.toLowerCase());
      } else {
        console.log(contact);
        return false;
      }
    });
  }
  sortarraybytime(array) {
    return array
      .sort(function (x, y) {
        return x.chat.lm.time - y.chat.lm.time;
      })
      .reverse();
  }
  rowRenderer = ({ index, key, style, parent }) => {
    // console.log(parent);
    const contact = parent.props.itemData[index];
    // if(contact.name.length<1){
    //   return null;
    // }
    return (
      <li
        key={key}
        style={style}
        onClick={() => {
          this.props.handleActiveChat(
            contact
            // chats[contact.id]
          );
          // this.props.markSeenAllMessages(contact.uid)
          this.props.mainSidebar(false);
        }}
      >
        <div className="pr-1">
          {/* <span className="avatar avatar-md m-0">
                    <img
                      src={contact.pic ? contact.pic : img}
                      alt={contact.name}
                      height="38"
                      width="38"
                    />
                  </span> */}

          <div className="avatar">
            <img
              src={contact.pic ? contact.pic : img}
              alt={contact.name}
              height="38"
              width="38"
            />
            <span
              className={`${
                contact.connections
                  ? "avatar-status-online"
                  : "avatar-status-offline"
              }`}
            />
          </div>
        </div>
        <div className="user-chat-info">
          <div className="contact-info">
            <h5 className="text-bold-600 mb-0">{contact.name}</h5>
            {contact.position ? (
              <p className="truncate">{contact.position}</p>
            ) : null}
            {contact.company ? (
              <p className="truncate">{contact.company}</p>
            ) : null}
          </div>
        </div>
      </li>
    );
  };

  render() {
    console.log("In chatsidebar render");
    var contactsArr = [];
    var chatsArr = [];
    var chatContacts = this.props.chat.chatContacts;
    if (this.state.value.length === 0) {
      contactsArr = this.state.contactlist;
      chatsArr = chatContacts;
    } else {
      contactsArr = this.state.contactlist
        ? this.getfiltercontacts(this.state.contactlist)
        : [];
      chatsArr = chatContacts ? this.getfiltercontacts(chatContacts) : [];
    }
    // contactsArr.sort(function (a, b) {
    //   if (a.name < b.name) { return -1; }
    //   if (a.name > b.name) { return 1; }
    //   return 0;
    // })
    chatsArr = this.sortarraybytime(chatsArr);

    let renderChats = chatsArr
      ? chatsArr.map((chat) => {
          // console.log("In for lastmessage");
          // console.log(chat);
          // console.log(chats[chat.id]);
          let lastMsg = chat.chat.lm,
            lastMsgDate = new Date(
              lastMsg && lastMsg.time ? lastMsg.time : null
            ),
            lastMsgMonth = lastMsgDate.toLocaleString("default", {
              month: "short",
            }),
            lastMsgDay = lastMsgDate.getDate();
          // console.log(lastMsg);
          // console.log(lastMsgDate)
          let pendingMsg = chat.chat.us;
          // console.log("Peding msg");
          // console.log(pendingMsg);
          // let activeID = chat.id;
          var cmessage = lastMsg ? lastMsg.mes : null;
          // var ccolor = false;
          if (cmessage.startsWith("{")) {
            try {
              var finalmessage = JSON.parse(cmessage);
              if (finalmessage.msg) {
                cmessage = finalmessage.msg;
                // ccolor = true;
              }
            } catch (error) {
              // console.log(error);
            }
          }
         
          return (
            <li
              key={chat.id}
              onClick={() => {
                this.props.handleActiveChat(chat);
                this.props.mainSidebar(false);
                // alert("teeee");
                // this.props.markSeenAllMessages(chat.uid)
              }}
              className={`${
                this.props.activeChatID === chat.id ? "active" : ""
              }`}
            >
              <div className="pr-1">
                <span className="avatar avatar-md m-0">
                  <div className="avatar">
                    <img
                      src={chat.pic ? chat.pic : img}
                      alt={chat.name}
                      height="38"
                      width="38"
                    />
                    <span
                      className={`${
                        chat.connections
                          ? "avatar-status-online"
                          : "avatar-status-offline"
                      }`}
                    />
                  </div>
                </span>
              </div>
              <div className="user-chat-info">
                <div className="contact-info">
                  <h5 className="text-bold-600 mb-0">{chat.name}</h5>
                  <p className="truncate">{cmessage}</p>
                  {/* <div className="badge badge-pill badge-danger mt-25 float-right">
                  <span className="align-middle">3</span>
                </div> */}
                </div>
                <div className="contact-meta d-flex- flex-column">
                  <span className="float-right mb-25">
                    {lastMsgMonth + " " + lastMsgDay}
                  </span>
                  {pendingMsg > 0 ? (
                    <div className="unseen-msg">
                      <Badge
                        className="badge-md float-right"
                        color="primary"
                        pill
                      >
                        {pendingMsg}
                      </Badge>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
          );
        })
      : null;
    return (
      <Card className="sidebar-content h-100">
        {/* <span
          className="sidebar-close-icon"
          onClick={() => this.props.mainSidebar(false)}
        >
          <X size={15} />
        </span> */}
        <div className="chat-fixed-search">
          <div className="d-flex align-items-center">
            <div className="sidebar-profile-toggle position-relative d-inline-flex">
              <div
                className="avatar"
                // onClick={() => this.props.handleUserSidebar("open")}
              >
                {this.props.user.publicdata.pic ? (
                  <img
                    src={this.props.user.publicdata.pic}
                    alt="User Profile"
                    height="40"
                    width="40"
                  />
                ) : (
                  <img src={img} alt="User Profile" height="40" width="40" />
                )}
                {/* <span
                  className={
                    status === "dnd"
                      ? "avatar-status-busy"
                      : status === "away"
                      ? "avatar-status-away"
                      : status === "offline"
                      ? "avatar-status-offline"
                      : "avatar-status-online"
                  }
                /> */}
              </div>
            </div>

            <FormGroup className="position-relative has-icon-left mx-1 my-0 w-100">
              <Input
                className="round"
                type="text"
                placeholder="Search contact or start a new chat"
                onChange={(e) => this.handleOnChange(e)}
                value={this.state.value}
              />
              <div className="form-control-position">
                <Search size={15} />
              </div>
            </FormGroup>
            {firebase.auth().currentUser.uid===this.props.eventdata.settings.supportid?
            <>
            {this.state.showcontacts ? (
              <XCircle
                color="red"
                onClick={() => {
                  this.setState({
                    showcontacts: false,
                  });
                }}
                style={{ marginRight: 5, cursor: "pointer" }}
                size={40}
              />
            ) : (
              <PlusCircle
                color="red"
                onClick={() => {
                  console.log("Show Contacts");
                  this.setState({
                    showcontacts: true,
                    showloading: true,
                  });
                  this.getcontactlist();
                }}
                style={{ marginRight: 5, cursor: "pointer" }}
                size={40}
              />
            )}
            </>:null}
          </div>
        </div>
        <AutoSizer style={{ width: "100%" }}>
          {({ height, width }) => (
            <div
              className="chat-user-list list-group"
              options={{
                wheelPropagation: false,
              }}
            >
              {this.state.showcontacts ? (
                <div>
                  <div style={{ height: 50 }}>
                    <h3 className="primary p-1 mb-0">Contacts</h3>
                  </div>
                  {this.state.showloading ? (
                    <Spinner />
                  ) : (
                    <ul
                      className="chat-users-list-wrapper media-list"
                      style={{ backgroundColor:"#fff" }}
                    >
                      <List
                        height={height}
                        rowCount={contactsArr.length}
                        rowHeight={100}
                        width={width}
                        rowRenderer={this.rowRenderer}
                        itemData={contactsArr}
                      />

                      {/* <InfiniteScroll
                    dataLength={contactsArr.length} //This is important field to render the next data
                    hasMore={false}
                    loader={<h4>Loading...</h4>}
                  >
                    {contactsArr.map(contact => {
                      return (
                        
                      )

                    })}
                  </InfiniteScroll> */}
                    </ul>
                  )}
                </div>
              ) : (
                <>
                  <h3 className="primary p-1 mb-0">Networking Chats</h3>
                  {this.state.showloading ? (
                    <Spinner />
                  ) : chatsArr.length > 0 ? (
                    <ul
                      className="chat-users-list-wrapper media-list"
                      style={{ backgroundColor:"#fff",
                      // paddingBottom: 60
                     }}
                    >
                      {renderChats}
                     
                    </ul>
                  ) : (
                    <div className="chat-app-window">
                      <div
                        className={"start-chat-area d-flex"}
                        style={{ height: "calc(100vh - 13rem)" }}
                      >
                        <span className="mb-1 start-chat-icon">
                          <MessageSquare style={{ color: "#000" }} size={50} />
                        </span>
                        {firebase.auth().currentUser.uid===this.props.eventdata.settings.supportid?

                        <h4
                          className="py-50 px-1 sidebar-toggle start-chat-text"
                          style={{ color: "#000" }}
                          onClick={() => {
                            this.setState({
                              showcontacts: true,
                              showloading: true,
                            });
                            this.getcontactlist();
                            // this.props.mainSidebar(true)
                            // if (this.props.mql.matches === false) {
                            //   this.props.mainSidebar(true)
                            // } else {
                            //   return null
                            // }
                          }}
                        >
                          Start Conversation
                        </h4>:<h4
                          className="py-50 px-1 sidebar-toggle start-chat-text"
                          style={{ color: "#000" }}
                          onClick={() => {
                            this.props.changecurrenchatuser({
                              id: this.props.eventdata.settings.supportid,
                              name: "Support"
                            });
                            // this.setState({
                            //   showcontacts: true,
                            //   showloading: true,
                            // });
                            // this.getcontactlist();
                            // this.props.mainSidebar(true)
                            // if (this.props.mql.matches === false) {
                            //   this.props.mainSidebar(true)
                            // } else {
                            //   return null
                            // }
                          }}
                        >
                         Chat With Support
                        </h4>}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </AutoSizer>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chat: state.chatApp.chats,
    user: state.auth.login.data,
    constat: state.auth.login.constat,
    eventdata: state.auth.login.eventdata,

  };
};
export default connect(mapStateToProps, {
  getChats,changecurrenchatuser
})(ChatSidebar);
