import React from "react";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import Agendatypelist from "../../../../src/views/pages/Agendatypelist";
import { history } from "../../../history";
import { logoutWithFirebase } from "../../../redux/actions/auth/loginActions";
import { customizerState } from "../../../redux/actions/customizer";
// import { BsFillHouseFill, BsDisplayFill, BsFillChatSquareDotsFill, BsTable, BsPersonSquare, BsFillInfoSquareFill, BsFillCalendarFill, BsFillBriefcaseFill } from "@react-icons/all-files/bs";
import { BsFillCalendarFill } from "@react-icons/all-files/bs/BsFillCalendarFill";
import {
  BsFillChatSquareDotsFill
} from "@react-icons/all-files/bs/BsFillChatSquareDotsFill";
import { IoMdNotificationsOutline } from "@react-icons/all-files/io/IoMdNotificationsOutline";

import { BsTable } from "@react-icons/all-files/bs/BsTable";

import { FiLogOut } from "@react-icons/all-files/fi/FiLogOut";

import { AiFillIdcard } from "@react-icons/all-files/ai/AiFillIdcard";
import { AiOutlineReload } from "@react-icons/all-files/ai/AiOutlineReload";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};
const UserDropdown = (props) => {
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        onClick={(e) => handleNavigation(e, "/pages/account-settings")}
      >
        <Icon.User color={"#fff"} size={14} className="mr-50" />
        <span className="align-middle">My Profile</span>
      </DropdownItem>
      {/* <DropdownItem tag="a" href="#">
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem> */}
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => props.logoutWithFirebase()}
      >
        <FiLogOut size={14} color={"#fff"} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    notification: [],
    fullscreen: false,
    modal: false,
  };

  componentDidMount() {
    // console.log("Honney GOyal test");
    // console.log(this.props.customizerStatevalue)
    // setTimeout(() => {
    //   var noti = [];
    //   noti.push({
    //     id: "hysp3aMrZJO8ikTP3fWpFlqMho42",
    //     company: "NYUC",
    //     position: "Developer",
    //     name: "Honney Goyal",
    //     type: "chat",
    //     pic: "https://firebasestorage.googleapis.com/v0/b/virtual-event-e2893.appspot.com/o/undefined%2Fpic.jpg?alt=media&token=cca0e68f-6dd5-47cb-a21a-1ce6c8f893ae",
    //     chat: {
    //       lm: {
    //         mes: "hello",
    //         time: 1593696001975
    //       },
    //       us: 0
    //     }
    //   })
    //   this.setState({
    //     notification: noti
    //   })
    // }, 3000);
  }

  render() {
    return (
      <ul
        className="nav navbar-nav navbar-nav-user float-right"
        style={{ flexWrap: "nowrap" }}
      >
        <Modal
          isOpen={this.state.modal}
          toggle={() => {
            this.setState({
              modal: !this.state.modal,
            });
          }}
          style={{ height: "50%" }}
          className={"modal-dialog-centered  modal-lg"}
          contentClassName={"modaltest100"}
        >
          <ModalHeader
            toggle={() => {
              this.setState({
                modal: !this.state.modal,
              });
            }}
          >
            Agenda
          </ModalHeader>
          <ModalBody style={{ overflow: "auto", height: "100%" }}>
            <Agendatypelist
              agendadata={this.props.agendadata}
              onloadcall={() => {
                // this.setState({
                //   spinner: false
                // })
              }}
              redirecttopage={(url, videolink) => {
                this.setState({
                  modal: false,
                });
                console.log("Redirecting to:- " + url);
                if (url && url.length > 0) {
                  history.push({
                    pathname: url,
                    videolink: videolink,
                  });
                }
              }}
            />
          </ModalBody>
        </Modal>

        {!this.props.eventdata.settings.beforelaunch ? (
          <>
            <NavItem
              className="nav-search "
              style={{ display: "none" }}
              onClick={() => {
                console.log("Toggle Video call status");
                this.setState({
                  modal: true,
                });
                // history.push("/meetings")
              }}
            >
              <NavLink id="agendabutton" className="nav-link-search ">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    // marginBottom:-15
                  }}
                >
                  <BsTable
                    style={{ color: "#fff" }}
                    size={18}
                    data-tour="search"
                  />
                  <div
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Agenda
                  </div>
                </div>
              </NavLink>
            </NavItem>
            {/* <UncontrolledTooltip placement="audo" target="agendabutton">
              Click Here for Agenda
            </UncontrolledTooltip> */}
          </>
        ) : null}

        {this.props.eventdata.settings.bcard &&
        !this.props.eventdata.settings.beforelaunch ? (
          <>
            <NavItem
              className="nav-search"
              onClick={() => {
                console.log("Toggle Video call status");
                history.push("/bcard");
              }}
            >
              <NavLink id="bcardbutton" className="nav-link-search">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    // marginBottom:-15
                  }}
                >
                  <AiFillIdcard color={"#fff"} size={18} data-tour="search" />
                  <div
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Bcard
                  </div>
                </div>
              </NavLink>
            </NavItem>
            {/* <UncontrolledTooltip placement="audo" target="bcardbutton">
              Click Here for Business Card
            </UncontrolledTooltip> */}
          </>
        ) : (
          false
        )}
        {this.props.eventdata.settings.meetings &&
        !this.props.eventdata.settings.beforelaunch ? (
          <>
            <NavItem
              className="nav-search"
              onClick={() => {
                console.log("Toggle Video call status");
                history.push("/meetings");
              }}
            >
              <NavLink id="meetingbutton" className="nav-link-search">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    // marginBottom:-15
                  }}
                >
                  <BsFillCalendarFill
                    color={"#fff"}
                    size={18}
                    data-tour="search"
                  />
                  <div
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Meetings
                  </div>
                </div>
              </NavLink>
            </NavItem>
            {/* <UncontrolledTooltip placement="audo" target="meetingbutton">
              Click Here for Meetings
            </UncontrolledTooltip> */}
          </>
        ) : (
          false
        )}
          {/* {this.props.eventdata.settings.reload ? (
          <>
            <UncontrolledDropdown
              className="nav-search"
              id="reload"
              tag="li"
              onClick={() => {
                
                history.push("/qrscanner")
                // this.props.customizerState(true);
              }}
            >
              <DropdownToggle tag="a" className="nav-link nav-link-label">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    // marginBottom:-15
                  }}
                >
                  <MdQrCodeScanner color={"#fff"} size={18} />

                  <div
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    QRScan
                  </div>
                </div>
              </DropdownToggle>
            </UncontrolledDropdown>

          
          </>
        ) : null} */}
        {false&&this.props.eventdata.settings.reload ? (
          <>
            <UncontrolledDropdown
              className="nav-search"
              id="reload"
              tag="li"
              onClick={() => {
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      type: "reload",
                    })
                  );
                }
                // history.push("/chat")
                // this.props.customizerState(true);
              }}
            >
              <DropdownToggle tag="a" className="nav-link nav-link-label">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    // marginBottom:-15
                  }}
                >
                  <AiOutlineReload color={"#fff"} size={18} />

                  <div
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Reload
                  </div>
                </div>
              </DropdownToggle>
            </UncontrolledDropdown>

            {/* <UncontrolledTooltip placement="audo" target="mainchatbutton">
              Click Here to Reload
            </UncontrolledTooltip> */}
          </>
        ) : null}
        {false&&this.props.eventdata.settings.chat ? (
          <>
            <UncontrolledDropdown
              className="nav-search"
              id="mainchatbutton"
              tag="li"
              // className="dropdown-notification nav-item"
              onClick={() => {
                history.push("/notification");
                // this.props.customizerState(true);
              }}
            >
              <DropdownToggle tag="a" className="nav-link nav-link-label">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    // marginBottom:-15
                  }}
                >
                  <IoMdNotificationsOutline color={"#fff"} size={25} />
                 
                </div>
              </DropdownToggle>
            </UncontrolledDropdown>

            {/* <UncontrolledTooltip placement="audo" target="mainchatbutton">
              Click Here for Notifications
            </UncontrolledTooltip> */}
          </>
        ) : null}
{true?null:
<>
            <UncontrolledDropdown
              
              id="mainchatbutton"
              tag="li"
              className="dropdown-notification nav-item"
              onClick={() => {
                history.push("/chat");
                // this.props.customizerState(true);
              }}
            >
              <DropdownToggle tag="a" className="nav-link nav-link-label">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop:3
                    // marginBottom:-100
                  }}
                >
                  <BsFillChatSquareDotsFill color={"#fff"} size={20} />
                  {
                  this.props.totalnonread <= 0 ? null : (
                    <Badge pill color="danger" className="badge-up">
                      {this.props.totalnonread}
                    </Badge>
                  )}

                </div>
              </DropdownToggle>
            </UncontrolledDropdown>

           
          </>}

        {/* <UncontrolledDropdown
          tag="li"
          className="dropdown-user nav-item"
          style={{ marginTop: 5 }}
        >
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span
                className="user-name text-bold-600"
                style={{
                  marginTop: isMobile ? 0 : 5,
                  width: 80,
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                {this.props.userName}
              </span>
            </div>
            <span data-tour="user">
              {this.props.userImg ? (
                <img
                  src={this.props.userImg}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              ) : (
                <BsPersonSquare color={"#fff"} size={30} />
              )}
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown> */}
      </ul>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    eventdata: state.auth.login.eventdata,
    totalnonread: state.chatApp.chats.totalnonread,
    customizerStatevalue: state.customizer.customizer.customizerState,
    constat: state.auth.login.constat,
  };
};
export default connect(mapStateToProps, {
  logoutWithFirebase,
  customizerState,
})(NavbarUser);
