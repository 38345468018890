import React from "react"

const FullPageLayout = ({ children, ...rest }) => {
  
  return (
    <div
    style={{height:"100%"}}
    >

      
      {children}
      {/* <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main className="main w-100"></main>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default FullPageLayout
