import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import "../../../node_modules/react-add-to-calendar/dist/react-add-to-calendar.min.css";
import { history } from "../../history";
// import { history } from "../../../history";

// import { MdVideoCameraBack } from "@react-icons/all-files/md";

// import test from './test.mp4';
// import Draggable from 'react-draggable'; // The default
// import "../../../assets/scss/components/customizer.scss"
import { AiFillCaretRight } from "@react-icons/all-files/ai/AiFillCaretRight";
import { connect } from "react-redux";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";
import { changeAgendaVideo } from "../../redux/actions/customizer/index";
import AgendaDetail from "./AgendaDetail";
// import 'react-add-to-calendar/dist/react-add-to-calendar.css'
class Agendatypelist extends Component {
  state = {
    modal: false,
    isloading: true,
    videoloading: true,
    showagenda: false,
    currentvideo: null,
    modaltitle: "",
    active: "day1",
    // value: "",
    videoname: "",
    currentdata: [],
    filteredData: [],
    alltypes: [],
    alldates: [],
    selecteddata: [],
    value: "",
    paginationPageSize: 20,
    currenPageSize: "",
    getPageSize: "",
  };
  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.selectedagenda;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        return item.title.toLowerCase().includes(value.toLowerCase());
        // let startsWithCondition =
        //   item.title.toLowerCase().startsWith(value.toLowerCase())
        // let includesCondition =
        //   item.title.toLowerCase().includes(value.toLowerCase()) ||
        //   item.date.toLowerCase().includes(value.toLowerCase()) ||
        //   item.email.toLowerCase().includes(value.toLowerCase()) ||
        //   item.revenue.toLowerCase().includes(value.toLowerCase()) ||
        //   item.status.toLowerCase().includes(value.toLowerCase())

        // if (startsWithCondition) {
        //   return startsWithCondition
        // } else if (!startsWithCondition && includesCondition) {
        //   return includesCondition
        // } else return null
      });
      var filterdata = _.orderBy(filteredData, ["stime"], ["asc"]);
      this.setState({ filteredData: filterdata });
    }
  };
  handleClickOutside() {
    if (this.state.pollcustomizer) {
      this.setState({
        pollcustomizer: false,
      });
      // this.props.handleCustomizer(!this.props.customizerState);
    }
  }

  processcurrentagenda = (cagenda) => {
    var alldates = Object.keys(cagenda);
    console.log(alldates);
  };
  componentDidMount() {
    if (this.props.hasOwnProperty("onloadcall")) {
      this.props.onloadcall();
    }
    // firebase
    //   .database()
    //   .ref("games/data/agendanew")
    //   .once("value", (snap) => {
    //     console.log(snap.val());
    // var allagenda = snap.val();
    var allagenda = this.props.eventdata.agendanew;
    var agendas = {};
    // var offset = -45000000;
    var offset = 0;

    // agendas["All Sessions"]=[];
    Object.keys(allagenda).map((ag) => {
      var cagenda = allagenda[ag];

      // offset=0;
      console.log("Testtt");
      console.log(offset);
      console.log(cagenda.stime);
      var sdate = moment(cagenda.stime + offset).format("MMM DD ddd YYYY");
      var edate = moment(cagenda.etime + offset).format("MMM DD ddd YYYY ");
      // console.log(sdate);
      // console.log(edate);
      // var sdate = moment(cagenda.stime + offset);
      // var edate = moment(cagenda.etime + offset);
      var st = moment(cagenda.stime + offset).format("HH:mm");

      var et = moment(cagenda.etime + offset).format("HH:mm");
      var agtime;
      if (sdate !== edate) {
        // agtime = sdate + " " + st + " - " + edate + " " + et;
        agtime = sdate + " " + st + " - " + edate + " " + et;
      } else {
        agtime = st + " - " + et;
      }
      console.log(agtime);
      console.log(sdate);
      console.log(edate);

      console.log(st);
      console.log(et);
      cagenda["agtime"] = agtime;
      cagenda["agdate"] = sdate;
      // agendas["All Sessions"].push(cagenda);
      console.log(agendas);
      if (!agendas["All Sessions"]) {
        agendas["All Sessions"] = {};
        console.log("helll");
      }
      console.log(agendas);
      if (sdate in agendas["All Sessions"]) {
        agendas["All Sessions"][sdate].push(cagenda);
      } else {
        agendas["All Sessions"][sdate] = [];
        agendas["All Sessions"][sdate].push(cagenda);
      }

      if (cagenda.stype) {
        if (!agendas[cagenda.stype]) {
          agendas[cagenda.stype] = {};
        }
        if (sdate in agendas[cagenda.stype]) {
          agendas[cagenda.stype][sdate].push(cagenda);
        } else {
          agendas[cagenda.stype][sdate] = [];
          agendas[cagenda.stype][sdate].push(cagenda);
        }
      }
      // });
      console.log(agendas);
      console.log(Object.keys(agendas));
      var alldates = Object.keys(agendas["All Sessions"]);
      var filterdata = _.orderBy(
        agendas["All Sessions"][alldates[0]],
        ["stime"],
        ["asc"]
      );

      this.setState(
        {
          isloading: false,
          alldates: alldates,
          selectedtype: "All Sessions",
          selecteddate: alldates[0],
          alltypes: Object.keys(agendas),
          allagenda: agendas,
          selectedagenda: agendas["All Sessions"][alldates[0]],
          filteredData: filterdata,
        },
        () => {
          console.log(this.state);
        }
      );
    });
    // console.log("Checking for agendata");
    // console.log(this.props.agendadata);
    // if (this.props.agendadata) {
    //   this.setState({
    //     isloading: false,
    //     currentdata: this.props.agendadata.data[this.props.agendadata.active].data,
    //     active: this.props.agendadata.active,
    //     agendadata: this.props.agendadata.data
    //   })
    // } else {
    //   firebase.database().ref("games").once("value", (snap) => {
    //     console.log(snap.val());
    //     console.log(snap.val().agenda[snap.val().agendaday].data);
    //     this.setState({
    //       isloading: false,
    //       currentdata: snap.val().agenda[snap.val().agendaday].data,
    //       active: snap.val().agendaday,
    //       agendadata: snap.val().agenda
    //     })
    //   })
    // }
  }
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      videoloading: true,
    }));
  };
  render() {
   
    // const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    // var data = this.state.value.length ? this.state.filteredData : this.state.currentdata;
    if (this.state.isloading) {
      return <Spinner />;
    } else {
      // var agendakeys = Object.keys(agendadata);
      // console.log("In showagenda");
      // console.log(currentdata);
      var key = "All Sessions";
      var alldates = Object.keys(this.state.allagenda[key]);
      var filterdata = _.orderBy(
        this.state.allagenda[key][alldates[0]],
        ["stime"],
        ["asc"]
      );
      // var selectedagenda = filterdata;
      console.log("HHHHHHH");
      console.log(filterdata);
      return (
        <div className="">
          {/* <Smartnavigation selected={"agenda"}/> */}
          <div  className=" addbg">
          <AgendaDetail
                    maindata={{
                      selectedtype: key,
                      alldates: alldates,
                      selecteddate: alldates[0],
                      selectedagenda: filterdata,
                      filteredData: filterdata,
                      allagenda: this.state.allagenda,
                    }}
                  />
            {/* {this.state.alltypes.map((key, i) => {
              return (
                <div
                  onClick={() => {
                    var alldates = Object.keys(this.state.allagenda[key]);
                    var filterdata = _.orderBy(
                      this.state.allagenda[key][alldates[0]],
                      ["stime"],
                      ["asc"]
                    );

                    history.push({
                      pathname: "/agendadetail",
                      state: {
                        selectedtype: key,
                        alldates: alldates,
                        selecteddate: alldates[0],
                        selectedagenda: this.state.allagenda[key][alldates[0]],
                        filteredData: filterdata,
                        allagenda: this.state.allagenda,
                      },
                    });
                  }}
                  style={{
                    color: "#000",
                    backgroundColor: "rgb(222, 231, 244,0.8)",
                    borderRight: "1px solid #000",
                    cursor: "pointer",
                    margin: 5,
                    display: "flex",
                    // width: 90,
                    padding: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {key}
                  </div>
                  <AiFillCaretRight
                    style={{ stroke: "black", strokeWidth: "5" }}
                    size={18}
                    color={"#000"}
                  />
                 
                </div>
              );
            })} */}
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    eventdata: state.auth.login.eventdata,
    // customizerstate: state.customizer.customizer.customizerState,
    // pollcustomizer: state.customizer.customizer.pollcustomizer
  };
};
export default connect(mapStateToProps, {
  changeAgendaVideo,
})(Agendatypelist);
