import firebase from 'firebase/compat/app';
import React from "react";
import {
  Col, ListGroup,
  ListGroupItem, Row
} from "reactstrap";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";

class Documetmodaladmin extends React.Component {
  state = {
    currentlyselected: 0,
    currentdocument: "",
    spinner: true,
    data: [],
    mainspinner: true,
  };
  componentDidMount() {
    console.log("In documentmodal");
    // console.log(this.props.data);
    firebase.database().ref("games/documents").once("value", (snap) => {
      console.log(snap.val());
      console.log(snap.val() && snap.val().length > 0 ? snap.val()[0].url : "");
      this.setState({
        currentdocument: snap.val() && snap.val().length > 0 ? snap.val()[0].url : "",
        data: snap.val() ? snap.val() : [],
        mainspinner: false
      })
      this.props.onloadcall();
    })
    // console.log(this.props.data[0].url);

  }
  render() {
    if (this.state.mainspinner) {
      return (
        < Spinner />
      )
    }
    return (
      <React.Fragment>
        {this.state.data && this.state.data.length === 0 ?
          <h2 style={{ textAlign: "center" }}>No Document Found</h2>
          :
          <Row style={{ height: "100%" }}>

            <Col md="8" sm="8" >
              {this.state.spinner ?
                < Spinner /> : null}
              <iframe title={this.state.currentdocument} onLoad={() => {
                this.setState({ spinner: false })
              }} src={this.state.currentdocument} height="100%" width="100%" />
            </Col>
            <Col md="4" sm="4" style={{ overflowY: "auto", height: "100%" }} >

              <ListGroup>
                {/* <h4 className="mb-1 text-white" >
                Heading 1
              </h4> */}
                {this.state.data && this.state.data.length > 0 && this.state.data.map((d, i) => {
                  return (
                    <ListGroupItem active={i === this.state.currentlyselected ? true : false} key={i} onClick={() => {
                      console.log("Clicked");
                      this.setState({
                        currentlyselected: i,
                        currentdocument: d.url,
                        spinner: true
                      })
                    }}>
                      <div className="d-flex justify-content-between w-100">
                        <h5 className="mb-1" style={{ color: i === this.state.currentlyselected ? "#fff" : null }}>
                          {d.name}
                        </h5>
                        {/* <Icon.Download onClick={() => {
                        console.log("Downloading");
                      }} /> */}
                        {/* <small>Download</small> */}
                      </div>
                    </ListGroupItem>
                  )
                })}

                {/* <ListGroupItem >
                <div className="d-flex justify-content-between w-100">
                  <h5 className="mb-1 text-white">
                    Document 2
              </h5>
                  <small>3 days ago</small>
                </div>
                <p className="mb-1">
                  Donec id elit non mi porta gravida at eget metus. Maecenas
                  sed diam eget risus varius blandit.
            </p>
                <small>Donec id elit non mi porta.</small>
              </ListGroupItem> */}

              </ListGroup>
            </Col>
          </Row>}
      </React.Fragment >
    )
  }
}

export default Documetmodaladmin