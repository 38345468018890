import firebase from 'firebase/compat/app';
import React, { Component } from "react";
import Countdown from "react-countdown-now";
import Select from "react-select";

import ReactPlayer from "react-player";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import csImg from "../../assets/img/pages/rocket.png";
import "../../assets/scss/pages/coming-soon.scss";
import Spinner2 from "../../components/@vuexy/spinner/Loading-spinner";
import { history } from "../../history";
import Agendatypelist from "./Agendatypelist";
import Contactmodal from "./Contactmodal";
// import { Document, Page } from 'react-pdf';
import Documetmodal from "./Documetmodal";
import Documetmodaladmin from "./Documetmodaladmin";
import Legendtypelist from "./Legendtypelist";
import Videomodel from "./Videomodel";

export default class Modalview extends Component {
  state = {
    spinner: true,
    coupontype: null,
    couponloading: false,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (nextProps.openmodal !== this.props.openmodal) {
      this.setState({
        spinner: true,
        coupontype: null,
        couponloading: false,
      });
    }
  }

  getmodalbody = () => {
    // console.log(this.props);
    switch (this.props.modaltype) {
      case "pdf":
        return (
          <iframe
            title={"title"}
            onLoad={() => {
              this.setState({ spinner: false });
            }}
            id="vd-vault-1"
            allow="autoplay; encrypted-media"
            scrollig="0"
            width="100%"
            height="100%"
            src={
              "https://firebasestorage.googleapis.com/v0/b/eventapp-ntdc.appspot.com/o/eventdata" +
              encodeURIComponent(this.props.modalsrc) +
              "?alt=media"
            }
          ></iframe>
        );
      case "image":
        return (
          <img
            id=""
            title={"title"}
            width="100%"
            // height="100%"
            src={
              "https://firebasestorage.googleapis.com/v0/b/eventapp-ntdc.appspot.com/o/eventdata" +
              encodeURIComponent(this.props.modalsrc) +
              "?alt=media"
            }
          ></img>
        );
      case "iframe":
        return (
          <iframe
            id=""
            title={"title"}
            onLoad={() => {
              this.setState({ spinner: false });
              if (this.props.modalsrc.includes("tawk")) {
                var ifrm = document.getElementById("tawkiframe");
                console.log(ifrm);
                // ifrm.contentWindow.Tawk_API.setAttributes({
                //   name: 'visitor name',
                //   email: 'visitor@email.com',
                // });
              }
            }}
            allow="autoplay;fullscreen; picture-in-picture; encrypted-media"
            scrollig="0"
            width="100%"
            height="100%"
            src={this.props.modalsrc}
          ></iframe>
        );
      case "ivideo":
        return (
          <iframe
            title={"title"}
            onLoad={() => {
              this.setState({ spinner: false });
            }}
            allow="autoplay; encrypted-media"
            scrollig="0"
            width="100%"
            height="100%"
            src={this.props.modalsrc}
          ></iframe>
        );
      case "video":
        console.log("playing video1");
        return (
          <ReactPlayer
            className="react-player"
            style={{ margin: "auto" }}
            onReady={() => {
              this.setState({ spinner: false });
            }}
            playing
            controls={true}
            height="100%"
            width="100%"
            url={this.props.modalsrc}
          />
        );

      case "agendatypelist":
        console.log("agendatypelist");
        return (
          <Agendatypelist
            agendadata={this.props.agendadata}
            onloadcall={() => {
              this.setState({
                spinner: false,
              });
            }}
            redirecttopage={(url, videolink) => {
              this.props.redirecttopage(url, videolink);
            }}
          />
        );
      case "legendtypelist":
        console.log("agendatypelist");
        return (
          <Legendtypelist
            agendadata={this.props.agendadata}
            onloadcall={() => {
              this.setState({
                spinner: false,
              });
            }}
            redirecttopage={(url, videolink) => {
              this.props.redirecttopage(url, videolink);
            }}
          />
        );
      case "csoon":
        console.log("In coming soon");
        return (
          <Card className="mb-0">
            <CardHeader className="justify-content-center">
              <h2>We are launching soon</h2>
            </CardHeader>
            <CardBody className="text-center">
              <img src={csImg} alt="csImg" className="img-fluid width-150" />
              <div className="text-center getting-started pt-2 d-flex justify-content-center flex-wrap">
                <Countdown
                  date={Date.now() + parseInt(this.props.modalsrc)}
                  renderer={this.renderTimer}
                />
              </div>
            </CardBody>
          </Card>
        );
      case "docs":
        console.log("showing docs");
        console.log(this.props.modaldata);
        return (
          <Documetmodal
            data={this.props.modaldata}
            onloadcall={() => {
              this.setState({
                spinner: false,
              });
            }}
          />
        );
      case "docsadmin":
        console.log("showing docs");
        console.log(this.props.modaldata);
        return (
          <Documetmodaladmin
            data={this.props.modaldata}
            onloadcall={() => {
              this.setState({
                spinner: false,
              });
            }}
          />
        );
      case "contacts":
        console.log("showing docs");
        console.log(this.props.modaldata);
        return (
          <Contactmodal
            data={this.props.modaldata}
            toggleModal={() => {
              this.props.toggleModal();
            }}
            onloadcall={() => {
              this.setState({
                spinner: false,
              });
            }}
          />
        );
      case "customgame":
        return (
          <Card className="mb-0">
            <CardBody className="">
              {this.props.modaldata.countdown ? (
                <div className="text-center getting-started pt-2 d-flex justify-content-center flex-wrap">
                  <Countdown
                    date={this.props.modaldata.countdowntime}
                    renderer={this.renderTimer}
                  />
                </div>
              ) : null}
              {this.props.modaldata.body ? (
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.modaldata.body,
                  }}
                ></div>
              ) : null}
            </CardBody>
          </Card>
        );
      case "coupon":
        return (
          <Card className="mb-0">
            <CardBody className="">
              {this.props.coupondata === "NA" ? (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log("Form submitted");
                    console.log(this.state.coupontype);
                    this.setState({
                      couponloading: true,
                    });
                    var getcoupon = firebase
                      .app()
                      .functions("asia-east2")
                      .httpsCallable("getcoupon");
                    getcoupon({
                      coupontype: this.state.coupontype.value,
                    }).then((data) => {
                      console.log(data);
                      console.log(data.data.err);
                      console.log(JSON.stringify(data));
                      this.props.getcoupon(data.data);
                    });
                  }}
                >
                  <FormGroup className="form-label-group">
                    <Select
                      required
                      disabled={this.state.couponloading}
                      className="React"
                      classNamePrefix="select"
                      value={this.state.coupontype}
                      onChange={(a) => {
                        this.setState({
                          coupontype: a,
                        });
                      }}
                      placeholder="Coupon Type"
                      options={[
                        {
                          value: "Zomato",
                          label: "Zomato",
                        },
                        {
                          value: "Swiggy",
                          label: "Swiggy",
                        },
                      ]}
                    />
                    <Label>Coupon Type</Label>
                  </FormGroup>

                  <div className="d-flex justify-content-center">
                    <Button.Ripple
                      disabled={
                        !this.state.coupontype || this.state.couponloading
                      }
                      color="primary"
                      type="submit"
                    >
                      {this.state.couponloading ? <Spinner /> : "Submit"}
                    </Button.Ripple>
                  </div>
                </Form>
              ) : this.props.coupondata.coupon ? (
                <h1>
                  Your {this.props.coupondata.coupontype} Coupon is{" "}
                  {this.props.coupondata.coupon}
                </h1>
              ) : (
                <h1>We have recieved your request for coupon</h1>
              )}
            </CardBody>
          </Card>
        );
      case "videolist":
        return (
          <Videomodel
            data={this.props.modaldata}
            onloadcall={() => {
              this.setState({
                spinner: false,
              });
            }}
          />
        );
      default:
        return null;
    }
  };
  render() {
    return (
      <Modal
        isOpen={this.props.openmodal}
        toggle={this.props.toggleModal}
        style={{ height: "50%" }}
        className={`modal-dialog-centered  ${
          this.props.modaltype === "docs" ||
          this.props.modaltype === "pdf" ||
          this.props.modaltype === "videolist" ||
          this.props.modaltype === "image" ||
          this.props.modaltype === "agendatypelist"
            ? "modaltest10 modal-xl"
            : "modaltest1 modal-lg"
        }`}
        contentClassName={
          this.props.modalheight
            ? "modaltest" + this.props.modalheight
            : "modaltest80"
        }
      >
        <ModalHeader toggle={this.props.toggleModal}>
          {this.props.modalheader}
        </ModalHeader>
        <ModalBody style={{ overflow: "auto", height: "100%" }}>
          {this.props.spinner ? <Spinner2 /> : this.getmodalbody()}
          {/* {this.getmodalbody()} */}
        </ModalBody>

        {this.props.modaltype === "customgame" &&
        (this.props.modaldata.button1 || this.props.modaldata.button2) ? (
          <ModalFooter style={{ justifyContent: "center" }}>
            {this.props.modaldata.button1 ? (
              <Button
                color="primary"
                className="round"
                onClick={() => {
                  if (
                    this.props.modaldata.button1data &&
                    this.props.modaldata.button1data.url
                  ) {
                    if (this.props.modaldata.button1data.newtab) {
                      window.open(this.props.modaldata.button1data.url);
                    } else {
                      history.push(this.props.modaldata.button1data.url);
                    }
                  }
                }}
              >
                {this.props.modaldata.button1data
                  ? this.props.modaldata.button1data.text
                  : "Start"}
              </Button>
            ) : null}
            {this.props.modaldata.button2 ? (
              <Button
                color="primary"
                className="round"
                onClick={() => {
                  if (
                    this.props.modaldata.button2data &&
                    this.props.modaldata.button2data.url
                  ) {
                    if (this.props.modaldata.button2data.newtab) {
                      window.open(this.props.modaldata.button2data.url);
                    } else {
                      history.push(this.props.modaldata.button2data.url);
                    }
                  }
                }}
              >
                {this.props.modaldata.button2data
                  ? this.props.modaldata.button2data.text
                  : "Start"}
              </Button>
            ) : null}
            {/* <Button color="primary" className="round" onClick={() => {
              window.open("https://virtual-dc-excellence-awards.com/final_builds/car_rush/index.html")
            }}>
              Play Main Game
        </Button> */}
          </ModalFooter>
        ) : null}
      </Modal>
    );
  }
}
