import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
// import calenderReducer from "./calendar/"
// import dataList from "./data-list/"
import chatReducer from "./chat/"
import nyucReducers from "./nyucreducers"
// import navbar from "./navbar/Index"

const rootReducer = combineReducers({
  // calendar: calenderReducer,
  customizer: customizer,
  auth: auth,
  chatApp: chatReducer,
  nyucReducers: nyucReducers,
  // navbar: navbar,
  // dataList: dataList,

})

export default rootReducer
