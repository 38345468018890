import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { logoutWithFirebase } from "../../../../../redux/actions/auth/loginActions";

// import navigationConfig from "../../../../../configs/navigationConfig"
import SideMenuGroup from "./SideMenuGroup";
import { Badge } from "reactstrap";
import { ChevronRight } from "react-feather";
import { history } from "../../../../../history";
import Modalview from "../../../../../views/pages/Modalview";
// import GlobalModal from "../../../../../views/pages/GlobalModal";
import * as Icon from "react-feather";
import { connect } from "react-redux";
var navigation = [
  {
    "groupTitle": "Easy Navigation",
    "type": "groupHeader"
  },
  {
    "icon": "Home",
    "id": "help3",
    "navLink": "/",
    "title": "Home",
    "type": "item"
  },
  {
    "icon": "User",
    "id": "eventinfo",
    "navLink": "/pages/account-settings",
    "title": "My Profile",
    "type": "item"
  },
  {
    "icon": "Codesandbox",
    "id": "qrcode1",
    "navLink": "/qrcode",
    "title": "My QR",
    "type": "item"
  },
  {
    "icon": "MessageCircle",
    "id": "chat",
    "navLink": "/chat",
    "title": "Chat",
    "type": "item"
  },
  {
    "icon": "Cast",
    "id": "url",
    "navLink": "/url",
    "title": "Kaun Banega Hero ka Hero",
    "topdata": {
      "back": false,
      "title": "Q/A",
      "url": "https://app.sli.do/event/puekkTZiDWoaEVTFC1S9MC"
    },
    "type": "item"
  },
  {
    "icon": "BarChart",
    "id": "qrcode",
    "navLink": "/mobileconnectivity",
    "title": "Mobile Connectivity",
    "type": "item"
  },
  {
    "icon": "Briefcase",
    "id": "baggageinstructions",
    "navLink": "/baggageinstructions",
    "title": "Baggage Instructions",
    "type": "item"
  },
  {
    "icon": "Gitlab",
    "id": "dresscode",
    "navLink": "/dresscode",
    "title": "Dress Code",
    "type": "item"
  },
  {
    "icon": "Cast",
    "id": "Communication",
    "navLink": "/Communication",
    "title": "Communication",
    "type": "item"
  },
  {
    "icon": "HelpCircle",
    "id": "faq",
    "navLink": "/faq",
    "title": "FAQs",
    "type": "item"
  },
  {
    "icon": "Sun",
    "id": "weather",
    "navLink": "/weather",
    "title": "Weather Update",
    "type": "item"
  },
  {
    "icon": "AlertCircle",
    "id": "dosdonts",
    "navLink": "/dosdonts",
    "title": "DOs & DON'Ts",
    "type": "item"
  },
  {
    "icon": "Globe",
    "id": "HelpfulWords",
    "navLink": "/helpfulwords",
    "title": "Helpful Words",
    "type": "item"
  },
  {
    "icon": "DollarSign",
    "id": "currencyexchange",
    "navLink": "/currencyexchange",
    "title": "Currency Exchange",
    "type": "item"
  },
  {
    "icon": "Map",
    "id": "GentingAttraction",
    "navLink": "/gentingattraction",
    "title": "Genting Attractions",
    "type": "item"
  },
  // {
  //   "icon": "User",
  //   "id": "speakersprofile",
  //   "navLink": "/speakersprofile",
  //   "title": "Speaker Profile",
  //   "type": "item"
  // },
  {
    "icon": "Users",
    "id": "roarcrew",
    "navLink": "/roarcrew",
    "title": "Roar Crew",
    "type": "item"
  },
  
  {
    "icon": "Users",
    "id": "importantcontacts",
    "navLink": "/importantcontacts",
    "title": "Important Contacts & Services",
    "type": "item"
  },
  
  {
    "icon": "LogOut",
    "id": "logout",
    "title": "LogOut",
    "type": "logout"
  }
]
class SideMenuContent extends React.Component {
  constructor(props) {
    super(props);

    this.parentArr = [];
    this.collapsedPath = null;
    this.redirectUnauthorized = () => {
      history.push("/pages/login");
    };
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    modal: false,
    modalsrc: "",
    modaltype: "",
    modalheader: "",
    modaldata: {},
    modalheight: null,
  };

  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = this.state.activeGroups;
    let active_group = this.state.currentActiveGroup;
    let temp_arr = this.state.tempArr;
    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === "collapse" && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      console.log("logging out");
      this.props.logoutWithFirebase();
      temp_arr = [];
    }

    if (type === "collapse") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1;
        });
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj);
          });
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0);
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group,
    });
  };

  initRender = (parentArr) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false,
    });
  };

  componentDidMount() {
    // console.log("Testing 123333");

    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths);
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      );
    }
  }

  render() {
    // Loop over sidebar items
    // eslint-disable-next-line
    // var navigationitems = this.props.eventdata.navigation;
    var navigationitems=navigation;
    const menuItems = navigationitems.map((item) => {
      var IconTag = Icon[item.icon];
      if (!IconTag) {
        IconTag = Icon["Home"];
      }
      // console.log(IconTag);
      const CustomAnchorTag =
        item.type === "external-link"
          ? `a`
          : item.type === "modal"
          ? "a"
          : Link;
      // checks if item has groupheader
      if (item.type === "groupHeader") {
        return (
          <li
            className="navigation-header"
            key={`group-header-${item.groupTitle}`}
          >
            <span>{item.groupTitle}</span>
          </li>
        );
      }
      // console.log("Side menu content");
      // console.log(this.props.activeItemState);
      // console.log(item);
      // console.log(this.props.activeParams)
      let renderItem = (
        <li
          className={classnames("nav-item", {
            "has-sub": item.type === "collapse",
            open: this.state.activeGroups.includes(item.id),
            "sidebar-group-active": this.state.currentActiveGroup.includes(
              item.id
            ),
            hover: this.props.hoverIndex === item.id,
            active:
              (this.props.activeItemState === item.navLink &&
                item.type === "item") ||
              (this.props.activeItemState.includes("pageid") &&
                this.props.activeParams.pageid &&
                item.navLink &&
                item.navLink.includes(this.props.activeParams.pageid)),
            disabled: item.disabled,
          })}
          key={item.id}
          onClick={(e) => {
            e.stopPropagation();
            if (item.type === "item") {
              this.props.handleActiveItem(item.navLink);
              this.handleGroupClick(item.id, null, item.type);
              if (this.props.deviceWidth <= 1921 && item.type === "item") {
                this.props.toggleMenu();
              }
            } else {
              this.handleGroupClick(item.id, null, item.type);
            }
          }}
        >
          {item.type === "modal" ? (
            <a
              className={`d-flex ${
                item.badgeText
                  ? "justify-content-between"
                  : "justify-content-start"
              }`}
              onMouseEnter={() => {
                this.props.handleSidebarMouseEnter(item.id);
              }}
              onMouseLeave={() => {
                this.props.handleSidebarMouseEnter(item.id);
              }}
              href={"#" + item.title}
              key={item.id}
              onClick={(e) => {
                // console.log()
                this.setState({
                  modal: true,
                  modaltype: item.modaltype,
                  modalsrc: item.modalsrc,
                  modalheader: item.title,
                  modaldata: item.data,
                  modalheight: item.modalheight ? item.modalheight : null,
                });
                console.log(item);
              }}
            >
              <div className="menu-text">
                <IconTag size={20} />
                {/* {item.icon} */}
                <span className="menu-item menu-title">{item.title}</span>
              </div>
            </a>
          ) : (
            <CustomAnchorTag
              // to={item.filterBase ? item.filterBase : item.navLink && item.type === "item" ? item.navLink : ""}
              href={item.type === "external-link" ? item.navLink : ""}
              className={`d-flex ${
                item.badgeText
                  ? "justify-content-between"
                  : "justify-content-start"
              }`}
              onMouseEnter={() => {
                this.props.handleSidebarMouseEnter(item.id);
              }}
              onMouseLeave={() => {
                this.props.handleSidebarMouseEnter(item.id);
              }}
              key={item.id}
              onClick={(e) => {
                if (item.type === "collapse") {
                  e.preventDefault();
                } else {
                  console.log(item);
                  if(item && item.topdata && item.topdata.url){
                    console.log("This is for url");
                    window.localStorage.setItem("url", item.topdata.url);
                  }
                  history.push({
                    pathname: item.navLink
                  });
                }
              }}
              target={item.newTab ? "_blank" : undefined}
            >
              <div className="menu-text">
                <IconTag size={20} />
                <span className="menu-item menu-title">{item.title}</span>
              </div>

              {item.badge ? (
                <div className="menu-badge">
                  <Badge color={item.badge} className="mr-1" pill>
                    {item.badgeText}
                  </Badge>
                </div>
              ) : (
                ""
              )}
              {item.type === "collapse" ? (
                <ChevronRight className="menu-toggle-icon" size={13} />
              ) : (
                ""
              )}
            </CustomAnchorTag>
          )}
          {item.type === "collapse" ? (
            <SideMenuGroup
              group={item}
              handleGroupClick={this.handleGroupClick}
              activeGroup={this.state.activeGroups}
              handleActiveItem={this.props.handleActiveItem}
              activeItemState={this.props.activeItemState}
              handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
              activePath={this.props.activePath}
              hoverIndex={this.props.hoverIndex}
              initRender={this.initRender}
              parentArr={this.parentArr}
              triggerActive={undefined}
              currentActiveGroup={this.state.currentActiveGroup}
              permission={this.props.permission}
              currentUser={this.props.currentUser}
              redirectUnauthorized={this.redirectUnauthorized}
              collapsedMenuPaths={this.props.collapsedMenuPaths}
              toggleMenu={this.props.toggleMenu}
              deviceWidth={this.props.deviceWidth}
            />
          ) : (
            ""
          )}
        </li>
      );

      if (
        item.navLink &&
        item.collapsed !== undefined &&
        item.collapsed === true
      ) {
        this.collapsedPath = item.navLink;
        this.props.collapsedMenuPaths(item.navLink);
      }

      if (
        item.type === "collapse" ||
        item.type === "external-link" ||
        item.type === "modal" ||
        (item.type === "item" &&
          item.permissions &&
          item.permissions.includes(this.props.currentUser)) ||
        item.permissions === undefined
      ) {
        return renderItem;
      } else if (
        item.type === "item" &&
        item.navLink === this.props.activePath &&
        !item.permissions.includes(this.props.currentUser)
      ) {
        return this.redirectUnauthorized();
      }
    });
    return (
      <React.Fragment>
        {/* <GlobalModal /> */}
        <Modalview
          openmodal={this.state.modal}
          redirecttopage={(url, videolink) => {
            this.setState({
              modal: false,
            });
            console.log("Redirecting to:- " + url);
            if (url && url.length > 0) {
              history.push({
                pathname: url,
                videolink: videolink,
              });
            }
          }}
          toggleModal={() => {
            this.setState((prevState) => ({
              modal: !prevState.modal,
            }));
          }}
          modalheader={this.state.modalheader}
          modalheight={this.state.modalheight}
          modaldata={this.state.modaldata}
          modalsrc={this.state.modalsrc}
          modaltype={this.state.modaltype}
        />

        {menuItems}
      </React.Fragment>
    );
  }
}
// export default SideMenuContent

const mapStateToProps = (state) => {
  return {
    eventdata: state.auth.login.eventdata,
    // homevideo: state.customizer.customizer.homevideo
  };
};
export default connect(mapStateToProps,  {
  logoutWithFirebase
})(SideMenuContent);
