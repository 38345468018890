const initialState = {
    feed: {},
    globalmodalstate: false,
    globalmodaltype: "",
    globalmodaldata: {}

}

const nyucReducers = (state = initialState, action) => {
    switch (action.type) {
      
        case "SHOW_GLOBAL_MODAL":
            console.log(action);
            return {
                ...state,
                globalmodalstate: action.value.state,
                globalmodaltype: action.value.type,
                globalmodaldata: action.value.data
            }
        case "ADD_COMMENT":
            return state
        default:
            return state
    }
}

export default nyucReducers
